import {
  branch_link,
  cart_icon,
  inner,
  search_input,
  search_wrap,
  toggle,
  top_fix,
  wrapper
} from 'Styles/modules/m-header.module.scss';
import { useEffect, useState } from 'react';

import { CMS_NAME } from 'Lib/constants/settings';
import Image from 'next/image';
import Link from 'next/link';
import MiniCart from 'Components/Cart/MiniCart';
import MobileNav from './MobileNav';
import { PATH } from 'Lib/constants/path';
import classNames from 'classnames';
import logo from 'img/logo_f_i.png';

const MobileHeader = () => {
  const [isTopfix, setTopfix] = useState('');
  const [styles, setStyles] = useState('');

  const listenScrollEvent = (event) => {
    const scrollBreakPoint = 84;
    if (window.scrollY >= 0 && window.scrollY <= scrollBreakPoint) {
      let opacityVal = 1 - window.scrollY / scrollBreakPoint;
      opacityVal = opacityVal > 0 ? opacityVal : 0;

      setStyles({
        ...styles,
        logo: {
          opacity: opacityVal,
          marginTop: 8,
          transition: 'all 0.5s'
        }
      });
      setTopfix('');
    }

    if (window.scrollY > scrollBreakPoint) {
      setTopfix(top_fix);
      setStyles({
        ...styles,
        logo: {
          opacity: 0,
          marginTop: -42,
          transition: 'all 0.5s'
        }
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () => window.removeEventListener('scroll', listenScrollEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header className={classNames(wrapper, isTopfix, 'lg:hidden')}>
      <div className="container leading-6">
        <div className={inner}>
          <Link href="/" passHref alt={CMS_NAME} aria-label={CMS_NAME}>
            <a className={branch_link} style={styles.logo}>
              <Image height={20} width={120} alt="logo" src={logo} />
            </a>
          </Link>
          <MiniCart className={cart_icon} />
          <MobileNav className={toggle} />
        </div>
        <div className={search_wrap}>
          <Link href={PATH.MOBILESEARCH}>
            <a>
              <div className={classNames(search_input, 'rounded')}>
                <span>Tìm sản phẩm yêu thích </span>
              </div>
            </a>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default MobileHeader;
