// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { BANNER_CONFIG } from 'Lib/constants/image';
import Image from 'next/image';
import { cloudinaryLoader } from 'functions';

const sliderParam = {
  spaceBetween: 10,
  slidesPerView: 1,
  autoplay: {
    delay: BANNER_CONFIG.delay,
    disableOnInteraction: false
  },
  speed: BANNER_CONFIG.speed,
  modules: [Navigation, Autoplay],
  loop: true
};

const DesktopSlider = ({ className, data }) => {

  return (
    <Swiper className={className} {...sliderParam}>
      {data.map((image, index) => (
        <SwiperSlide key={index}>
          <Image
            src={image?.cloudinaryResource?.url_suffix || image?.sourceUrl}
            alt={image.altText}
            width={BANNER_CONFIG.desktop.width}
            height={BANNER_CONFIG.desktop.height}
            loader={image?.cloudinaryResource ? cloudinaryLoader : ''}
            layout="intrinsic"
          />
        </SwiperSlide>
      ))}
      <div className="swiper-pagination"></div>
    </Swiper>
  );
};

export default DesktopSlider;
