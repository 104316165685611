
import Container from 'Components/Container';
import DesktopSlider from 'Components/DesktopSlider';
import MobileHeader from 'Components/Headers/MobileHeader';
import Layout from 'Components/Layout';
import MobileSlider from 'Components/MobileSlider';
import ProductSection from 'Components/Product/ProductSection';
import client from 'Lib/ApolloClient';
import { CMS_NAME } from 'Lib/constants/settings';
import HOMEPAGE_QUERY from 'Queries/HOMEPAGE.js';
import { getBanner } from 'functions';
import Head from 'next/head';

export default function Index({
  newArrival,
  bestSeller,
  womanPerfume,
  manPerfume,
  unisexPerfume,
  giftSet,
  banner }) {


  return (
    <Layout className="homepage" isFooter>
      <Head>
        <title>{CMS_NAME}</title>
      </Head>
      <MobileHeader />
      <Container className="mb-5">
        {getBanner(banner).desktop.length > 0 && (
          <DesktopSlider
            className="hidden md:block lg:mt-4"
            data={getBanner(banner).desktop}
          />
        )}
        {getBanner(banner).mobile.length > 0 && (
          <MobileSlider className="md:hidden" data={getBanner(banner).mobile} />
        )}
        <div className="px-1 lg:px-0">
          {newArrival.length > 0 && (
            <ProductSection
              products={newArrival}
              title="Hàng mới về"
              itemClassName="md:grid-cols-5 mb-5"
              viewall={{
                url: "/category/new-arrivals"
              }}
            />
          )}
          {bestSeller.length > 0 && (
            <ProductSection
              products={bestSeller}
              title="Nước hoa trend"
              itemClassName="md:grid-cols-5 mb-5"
              viewall={{
                url: "/category/best-seller"
              }}
            />
          )}
          {womanPerfume.length > 0 && (
            <ProductSection
              products={womanPerfume}
              title="Nước hoa nữ"
              itemClassName="md:grid-cols-5 mb-5"
              viewall={{
                url: "/category/nuoc-hoa-cho-nu"
              }}
            />
          )}
          {manPerfume.length > 0 && (
            <ProductSection
              products={manPerfume}
              title="Nước hoa nam"
              itemClassName="md:grid-cols-5 mb-5"
              viewall={{
                url: "/category/nuoc-hoa-cho-nam"
              }}
            />
          )}
          {unisexPerfume.length > 0 && (
            <ProductSection
              products={unisexPerfume}
              title="Nước hoa Unisex"
              itemClassName="md:grid-cols-5 mb-5"
              viewall={{
                url: "/category/nuoc-hoa-unisex"
              }}
            />
          )}
          {giftSet.length > 0 && (
            <ProductSection
              products={giftSet}
              title="Giftset"
              itemClassName="md:grid-cols-5 mb-5"
              viewall={{
                url: "/category/bo-qua-tang-nuoc-hoa"
              }}
            />
          )}
        </div>
      </Container>
    </Layout>
  );
}

export async function getStaticProps() {
  const res = await client.query({
    query: HOMEPAGE_QUERY,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache'
  });
  const { data, loading, networkStatus } = res;

  return {
    props: {
      newArrival: data.newArrival.edges || [],
      bestSeller: data.bestSeller.edges || [],
      womanPerfume: data.womanPerfume.edges || [],
      manPerfume: data.manPerfume.edges || [],

      giftSet: data.giftSet.edges || [],
      unisexPerfume: data.unisexPerfume.edges || [],


      banner: data.banner.edges || [],
      loading,
      networkStatus
    }
  };
}

export const fetchCache = 'force-no-store'
