import { OUT_OF_STOCK } from 'Lib/constants/product';
import { URI_TYPE_PATH } from 'Lib/constants/path';

export const getProductBrand = (productBrands) =>
  productBrands?.nodes.length > 0 ? productBrands?.nodes[0] : '';

export const getProductBrandLink = (productBrand) => `${URI_TYPE_PATH.ProductBrand}/${productBrand.slug}`

export const getPerfumeNoteLink = (perfumeNote) => `${URI_TYPE_PATH.perfumeNote}/${perfumeNote.slug}`

export const getDefaultSelection = (variations) => {
  const availableVariation = variations.filter(e => e.stockStatus !== OUT_OF_STOCK)
  return availableVariation.length > 0 ? availableVariation[0] : null
}
