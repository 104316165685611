export const PRODUCT_SECTION_IMAGE_WIDTH_SIZES =
  '(min-width: 1540px) 246px, (min-width: 1280px) 203px, (min-width: 1040px) 161px, (min-width: 780px) 118px, (min-width: 640px) 153px, calc(50vw - 8px)';

export const IMAGE_QUALITY = 100;

export const PRODUCT_IMAGE_SIZE = {
  width: 180,
  height: 180
};

export const BANNER_CONFIG = {
  desktop: {
    width: 1200,
    height: 400
  },
  mobile: {
    width: 428,
    height: 214
  },
  delay: 2000,
  speed: 200
};
