import {
  IMAGE_QUALITY,
  PRODUCT_IMAGE_SIZE,
  PRODUCT_SECTION_IMAGE_WIDTH_SIZES
} from 'Lib/constants/image';
import { cloudinaryLoader, getLowestProductPrice } from 'functions';
import {
  info,
  item,
  link,
  p_brand,
  p_image,
  p_price
} from './section.module.scss';

import Link from 'next/link';
import { PATH } from 'Lib/constants/path';
import SiteImage from 'Components/Common/SiteImage';
import { getProductBrand } from 'utils/productCollectionUtils';
import productNameSanitize from 'utils/hooks/productNameSanitize';

export default function ProductSectionItem(props) {
  const { image, slug, name, price, productBrands, onSale, regularPrice } = props.item;
  const productBrand = getProductBrand(productBrands);

  return (
    <div className={item}>
      <div className='lg:rounded-lg overflow-hidden'>
        <Link href={`${PATH.PRODUCT}/${slug}`} prefetch={false}>
          <a aria-label={name}>
            <SiteImage
              width={PRODUCT_IMAGE_SIZE.width}
              height={PRODUCT_IMAGE_SIZE.height}
              alt={image?.altText}
              src={image?.cloudinaryResource?.url_suffix || image?.sourceUrl}
              layout="responsive"
              sizes={PRODUCT_SECTION_IMAGE_WIDTH_SIZES}
              quality={IMAGE_QUALITY}
              loader={image?.cloudinaryResource ? cloudinaryLoader : ''}
              className={p_image}
            />
          </a>
        </Link>
      </div>
      <div className={info}>
        <p className={p_brand}>{productBrand?.name}</p>
        <Link href={`${PATH.PRODUCT}/${slug}`} prefetch={false}>
          <a
            className={link}
            dangerouslySetInnerHTML={{
              __html: productNameSanitize(name, productBrand?.name)
            }}
          />
        </Link>
        <div className='flex'>
          <span className={p_price} dangerouslySetInnerHTML={{ __html: getLowestProductPrice(price) }} />
          {onSale ? <span className='pl-2 text-sm text-slate-600 line-through'>{getLowestProductPrice(regularPrice)}</span> : null}
        </div>
      </div>
    </div>
  );
}
