import { items, link, title, view_all, wrapper } from './section.module.scss';

import Link from 'next/link';
import ProductSectionItem from './ProductSectionItem';
import classNames from 'classnames';
import isEmpty from 'utils/isEmpty';

const ProductSection = (props) => {
  const {
    products,
    title: sectionTitle,
    viewall = {},
    className,
    itemClassName
  } = props;
  return (
    <section className={classNames(wrapper, className)}>
      {sectionTitle ?
        <div className={title}>
          <h2>{sectionTitle}</h2>
        </div>
        : null
      }
      <div className={classNames(items, itemClassName)}>
        {products ?
          products.map((item, index) =>
            <ProductSectionItem item={item.node} key={index} />
          )
          : null
        }
      </div>
      {!isEmpty(viewall.url) ?
        <div className={view_all}>

          <Link href={viewall.url}>
            <a>
              <span className={link} >
                Xem tất cả
              </span>
            </a>
          </Link>

        </div>
        : null
      }
    </section >
  );
};

export default ProductSection;
