// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { BANNER_CONFIG } from 'Lib/constants/image';
import Image from 'next/image';
import React from 'react';
import { cloudinaryLoader } from 'functions';

const MobileSlider = ({ className, data }) => {
  let sliderParam = {
    spaceBetween: 10,
    slidesPerView: 1,
    autoplay: {
      delay: BANNER_CONFIG.delay,
      disableOnInteraction: false
    },
    speed: BANNER_CONFIG.speed,
    modules: [Navigation, Pagination, Autoplay],
    loop: true
  };

  return (
    <Swiper className={className} {...sliderParam}>
      {data.map((image, index) => (
        <SwiperSlide key={index}>
          <Image
            src={image?.cloudinaryResource?.url_suffix || image?.sourceUrl}
            alt={image.altText}
            width={BANNER_CONFIG.mobile.width}
            height={BANNER_CONFIG.mobile.height}
            loader={image?.cloudinaryResource ? cloudinaryLoader : ''}
            layout="intrinsic"
          />
        </SwiperSlide>
      ))}
      <div className="swiper-pagination"></div>
    </Swiper>
  );
};

export default MobileSlider;
